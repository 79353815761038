import React from 'react';

const Logo = ({ color, className }) => {
  const primaryColor = color ? '#fff' : '#231f20';
  const secondaryColor = color ? '#fff' : '#3cacdb';
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 541.4 160.94" className={className}>
      <path d="M96.19,158c.13-.15.26-.33.39-.48.25-.34.53-.54.89-.23a7.08,7.08,0,0,0,4.31,1.93c2.07,0,3.42-1.3,3.42-2.8,0-1.76-1.53-2.81-4.46-4s-4.49-2.37-4.49-5.28c0-1.74,1.37-4.54,5.43-4.54a8.34,8.34,0,0,1,4.36,1.3.57.57,0,0,1,.15.87c-.1.15-.2.33-.3.48a.58.58,0,0,1-.9.23,7.1,7.1,0,0,0-3.34-1.17c-2.7,0-3.52,1.73-3.52,2.8,0,1.71,1.3,2.71,3.45,3.6,3.44,1.4,5.66,2.7,5.66,5.66,0,2.66-2.53,4.59-5.51,4.59a8.34,8.34,0,0,1-5.38-2.06A.56.56,0,0,1,96.19,158Z" style={{ fill: `${primaryColor}` }} />
      <path d="M116,143.32a.48.48,0,0,1,.48-.49h5.64a5.69,5.69,0,1,1,0,11.38H118v6a.5.5,0,0,1-.49.49h-1a.48.48,0,0,1-.48-.49Zm6,9a3.89,3.89,0,0,0,3.88-3.9,3.76,3.76,0,0,0-3.88-3.65h-4v7.55Z" style={{ fill: `${primaryColor}` }} />
      <path d="M144.2,142.58a9.18,9.18,0,1,1-9.16,9.21A9.16,9.16,0,0,1,144.2,142.58Zm0,16.58a7.4,7.4,0,1,0-7.37-7.37A7.42,7.42,0,0,0,144.2,159.16Z" style={{ fill: `${primaryColor}` }} />
      <path d="M162.39,143.32a.48.48,0,0,1,.48-.49h6.61a5.48,5.48,0,0,1,5.56,5.44,5.7,5.7,0,0,1-3.78,5.2l3.5,6.48a.48.48,0,0,1-.44.74H173a.45.45,0,0,1-.41-.23l-3.39-6.76h-4.87v6.5a.5.5,0,0,1-.48.49h-1a.48.48,0,0,1-.48-.49Zm7,8.7a3.67,3.67,0,1,0,0-7.33H164.4V152Z" style={{ fill: `${primaryColor}` }} />
      <path d="M186.87,144.54h-4.44a.48.48,0,0,1-.48-.48v-.74a.48.48,0,0,1,.48-.49h10.82a.48.48,0,0,1,.48.49v.74a.48.48,0,0,1-.48.48h-4.44V160.2a.5.5,0,0,1-.48.49h-1a.5.5,0,0,1-.49-.49Z" style={{ fill: `${primaryColor}` }} />
      <path d="M200.49,158c.13-.15.26-.33.39-.48.25-.34.53-.54.89-.23a7.08,7.08,0,0,0,4.31,1.93c2.07,0,3.42-1.3,3.42-2.8,0-1.76-1.53-2.81-4.47-4s-4.49-2.37-4.49-5.28c0-1.74,1.38-4.54,5.44-4.54a8.34,8.34,0,0,1,4.36,1.3.57.57,0,0,1,.15.87c-.1.15-.2.33-.3.48a.58.58,0,0,1-.9.23,7.1,7.1,0,0,0-3.34-1.17c-2.7,0-3.52,1.73-3.52,2.8,0,1.71,1.3,2.71,3.45,3.6,3.44,1.4,5.66,2.7,5.66,5.66,0,2.66-2.53,4.59-5.51,4.59a8.34,8.34,0,0,1-5.38-2.06A.56.56,0,0,1,200.49,158Z" style={{ fill: `${primaryColor}` }} />
      <path d="M233.12,142.93a.48.48,0,0,1,.46-.35h.4a.48.48,0,0,1,.44.28l5.05,14h.13l5-14a.47.47,0,0,1,.43-.28h.41a.48.48,0,0,1,.46.35l3.29,17.15c.08.35-.08.61-.46.61h-1a.53.53,0,0,1-.46-.36L245,147.19h-.1l-4.69,13.47a.5.5,0,0,1-.44.28h-.46a.5.5,0,0,1-.43-.28l-4.74-13.47H234l-2.22,13.14a.48.48,0,0,1-.45.36h-1c-.38,0-.53-.26-.45-.61Z" style={{ fill: `${primaryColor}` }} />
      <path d="M255.74,160l7.76-17.17a.44.44,0,0,1,.43-.28h.25a.46.46,0,0,1,.44.28l7.7,17.17a.45.45,0,0,1-.43.66h-1a.47.47,0,0,1-.44-.28l-1.88-4.21h-9.06l-1.86,4.21a.47.47,0,0,1-.43.28h-1A.45.45,0,0,1,255.74,160Zm12.12-5.41c-1.25-2.78-2.48-5.59-3.73-8.37h-.2l-3.73,8.37Z" style={{ fill: `${primaryColor}` }} />
      <path d="M280.2,143a.48.48,0,0,1,.49-.46h.63l11.51,14.33h.05V143.32a.48.48,0,0,1,.48-.49h.9a.5.5,0,0,1,.48.49v17.16a.48.48,0,0,1-.48.46h-.46l-11.71-14.61h0V160.2a.48.48,0,0,1-.48.49h-.89a.5.5,0,0,1-.49-.49Z" style={{ fill: `${primaryColor}` }} />
      <path d="M302.6,160l7.75-17.17a.46.46,0,0,1,.44-.28H311a.44.44,0,0,1,.43.28L319.18,160a.45.45,0,0,1-.44.66h-1a.46.46,0,0,1-.43-.28l-1.89-4.21h-9l-1.87,4.21a.46.46,0,0,1-.43.28h-1A.45.45,0,0,1,302.6,160Zm12.11-5.41c-1.25-2.78-2.47-5.59-3.72-8.37h-.2l-3.73,8.37Z" style={{ fill: `${primaryColor}` }} />
      <path d="M334.86,142.58A9,9,0,0,1,341,145a.49.49,0,0,1,0,.71c-.23.23-.51.49-.71.72s-.38.23-.66,0a7.82,7.82,0,0,0-4.85-2,7.35,7.35,0,0,0,0,14.7,9.58,9.58,0,0,0,4.59-1.07v-3.58H336.5A.47.47,0,0,1,336,154V153a.47.47,0,0,1,.49-.48h4.41a.48.48,0,0,1,.46.48v5.84a.64.64,0,0,1-.2.41,12.66,12.66,0,0,1-6.31,1.68,9.18,9.18,0,1,1,0-18.36Z" style={{ fill: `${primaryColor}` }} />
      <path d="M350.73,143.32a.48.48,0,0,1,.49-.49h10a.48.48,0,0,1,.48.49v.74a.48.48,0,0,1-.48.48h-8.58v6.23H360a.5.5,0,0,1,.49.48V152a.49.49,0,0,1-.49.49h-7.32V159h8.58a.48.48,0,0,1,.48.48v.74a.48.48,0,0,1-.48.49h-10a.48.48,0,0,1-.49-.49Z" style={{ fill: `${primaryColor}` }} />
      <path d="M373,142.93a.48.48,0,0,1,.46-.35h.4a.48.48,0,0,1,.44.28l5.05,14h.13l5-14a.48.48,0,0,1,.43-.28h.41a.48.48,0,0,1,.46.35L389,160.08c.08.35-.08.61-.46.61h-1a.53.53,0,0,1-.46-.36l-2.27-13.14h-.1L380,160.66a.5.5,0,0,1-.44.28h-.46a.5.5,0,0,1-.43-.28l-4.74-13.47h-.11l-2.22,13.14a.48.48,0,0,1-.45.36h-1c-.39,0-.54-.26-.46-.61Z" style={{ fill: `${primaryColor}` }} />
      <path d="M398,143.32a.48.48,0,0,1,.48-.49h10.05a.48.48,0,0,1,.49.49v.74a.48.48,0,0,1-.49.48H400v6.23h7.32a.5.5,0,0,1,.49.48V152a.49.49,0,0,1-.49.49H400V159h8.57a.48.48,0,0,1,.49.48v.74a.48.48,0,0,1-.49.49H398.5a.48.48,0,0,1-.48-.49Z" style={{ fill: `${primaryColor}` }} />
      <path d="M418.22,143a.48.48,0,0,1,.49-.46h.63l11.51,14.33h0V143.32a.48.48,0,0,1,.48-.49h.9a.5.5,0,0,1,.48.49v17.16a.48.48,0,0,1-.48.46h-.46l-11.71-14.61h0V160.2a.48.48,0,0,1-.48.49h-.89a.5.5,0,0,1-.49-.49Z" style={{ fill: `${primaryColor}` }} />
      <path d="M445.59,144.54h-4.44a.48.48,0,0,1-.48-.48v-.74a.48.48,0,0,1,.48-.49H452a.48.48,0,0,1,.48.49v.74a.48.48,0,0,1-.48.48h-4.44V160.2a.5.5,0,0,1-.49.49h-1a.5.5,0,0,1-.48-.49Z" style={{ fill: `${primaryColor}` }} />
      <path d="M41.25,1.62H3.09A3.08,3.08,0,0,0,0,4.71V112.22a3.08,3.08,0,0,0,3.09,3.08h.08L21.11,97.36V74.05h20.3A36.53,36.53,0,0,0,77.63,37.51C77.63,17.86,61.22,1.62,41.25,1.62ZM40,54.24H21.11V21.6H40c9.09,0,16.73,6.82,16.73,15.75A16.75,16.75,0,0,1,40,54.24Z" style={{ fill: `${primaryColor}` }} />
      <path d="M181.07,36.54c0-19.33-15.92-34.92-35.41-34.92H99.22a3.08,3.08,0,0,0-3.09,3.09V112.22a3.08,3.08,0,0,0,3.09,3.08h14.94a3.19,3.19,0,0,0,3.08-3.08V70.81h18l21.6,43a2.78,2.78,0,0,0,2.6,1.46h17.05a3.07,3.07,0,0,0,2.76-4.71L157,69.34C171.16,63.66,181.07,51.48,181.07,36.54ZM143.88,53.43H117.41V21.11h26.47c8.6,0,16.07,7.15,16.07,15.75C160,46,152.48,53.43,143.88,53.43Z" style={{ fill: `${primaryColor}` }} />
      <path d="M449.65,95.81H408.24V4.71a3.2,3.2,0,0,0-3.09-3.09H390.21a3.08,3.08,0,0,0-3.09,3.09V112.22a3.08,3.08,0,0,0,3.09,3.08h59.44a3.08,3.08,0,0,0,3.08-3.08V98.9A3.09,3.09,0,0,0,449.65,95.81Z" style={{ fill: `${secondaryColor}` }} />
      <path d="M538.31,95.81H490.24V67.4h40.11a3.09,3.09,0,0,0,3.09-3.09V50.83a3.21,3.21,0,0,0-3.09-3.09H490.24V21.11h48.07A3.09,3.09,0,0,0,541.4,18V4.71a3.09,3.09,0,0,0-3.09-3.09h-66.1a3.08,3.08,0,0,0-3.08,3.09V112.22a3.08,3.08,0,0,0,3.08,3.08h50.55l18.07-18.07A3,3,0,0,0,538.31,95.81Z" style={{ fill: `${secondaryColor}` }} />
      <path d="M217.27,58.63c0-20.47,16.73-37.52,37.19-37.52H312c.81,0,1.6.07,2.39.12L332.06,3.6A58.46,58.46,0,0,0,312,0H254.46a58.22,58.22,0,0,0-58.3,58.63,57.94,57.94,0,0,0,29.87,51l16-16A37.3,37.3,0,0,1,217.27,58.63Z" style={{ fill: `${primaryColor}` }} />
      <path d="M344.06,9.59,328.52,25.13A37.26,37.26,0,0,1,312,95.81H257.84l-19,19a59.23,59.23,0,0,0,15.6,2.13H312a58.47,58.47,0,0,0,32-107.33Z" style={{ fill: `${secondaryColor}` }} />
    </svg>
  );
};

export default Logo;
