import React from 'react'

const Footer = class extends React.Component {
  render() {
    return (
      <footer>
        <section className="bg-dark-gray">
          <div className="mw8-ns center flex flex-wrap pa4">
            <div className="w-100 w-50-ns flex items-center lh-solid pb4 pb0-ns justify-center justify-start-ns">
            <i className="fas fa-mobile-alt white mr1" />
              <a href="tel:+5511984625186" className="white">+55 11 98462-5186</a>
            </div>
            <div className="w-100 w-50-ns flex items-center lh-solid justify-center justify-end-ns">
            <i className="far fa-envelope white mr1" />
              <a href="mailto:contato@prolesports.com" className="white">contato@prolesports.com</a>
            </div>
          </div>
        </section>
        <section className="bg-black-80">
          <div className="mw8-ns center flex flex-wrap pa4">
            <div className="w-100 w-50-ns">
              <ul className="list ma0 flex o-50">
                <li className="mr3 mb3 mb0-l">
                  <a href="https://instagram.com/prolesports" className="white" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-instagram" />
                  </a>
                </li>
                <li className="mr3">
                  <a href="https://www.youtube.com/channel/UC0LAzK1TrQthtHjsXrpJWmQ" className="white" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-youtube" />
                  </a>
                </li>
                <li className="">
                  <a href="https://www.facebook.com/prolesports" className="white" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-facebook" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="w-100 w-50-ns light-gray f7 tc tr-ns">
              &copy; {new Date().getFullYear()} Prole Sports Management. Todos os direitos reservados.
            </div>
          </div>
        </section>
      </footer>
    )
  }
}

export default Footer
