import React, { Component } from 'react'
import { Link } from 'gatsby'
import Logo from '../img/logo'

const Navbar = class extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  render() {
    return (
      <nav id="nav" className="fixed absolute-l top-0 left-0 right-0 z-max">
        <div className="mw9-ns center">
          <div className="bb flex items-center pv2 pv4-l justify-between tc b--white-30 ph3">
            <div id="nav-bars">
              <label htmlFor="nav-float-toggle" className="ba bw1 br-pill w2 h2 flex items-center justify-center lh-solid no-underline">
                <i className="fas fa-bars" style={{ marginLeft: '1px' }} />
              </label>
            </div>
            <Link to="/" className="db w-30 w3-m w5-l">
              <Logo color="white" className="dn db-l" />
              <Logo className="db dn-l" />
            </Link>
            <div />
          </div>
          <input type="checkbox" id="nav-float-toggle" className="dn" />
          <div id="nav-float" className="db fixed static-l w5 w-auto-l left-0 z-max vh-100 h-auto-l">
            <div className="flex flex-wrap justify-between pv3 tc ph3">
              <div className="w-100 w-10-l">
                <ul className="list ma0 flex o-50">
                  <li className="mr3 mb3 mb0-l">
                    <a href="https://instagram.com/prolesports" className="white" target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                  <li className="mr3">
                    <a href="https://www.youtube.com/channel/UC0LAzK1TrQthtHjsXrpJWmQ" className="white" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-youtube" />
                    </a>
                  </li>
                  <li className="">
                    <a href="https://www.facebook.com/prolesports" className="white" target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-facebook" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="w-100 w-80-l">
                <ul className="list flex mt4 mt0-l ml0 ttu f7 justify-start justify-center-l flex-wrap tl tc-l">
                  <li className="mb4 ph3-l mb0-l w-100 w-auto-l"><Link to="/" className="no-underline white pb1" activeClassName="bb bw1">Home</Link></li>
                  <li className="mb4 ph3-l mb0-l w-100 w-auto-l"><Link to="/about" className="no-underline white pb2" activeClassName="bb bw1">Quem Somos</Link></li>
                  <li className="mb4 ph3-l mb0-l w-100 w-auto-l"><Link to="/services" className="no-underline white pb2" activeClassName="bb bw1">Serviços</Link></li>
                  <li className="mb4 ph3-l mb0-l w-100 w-auto-l"><Link to="/youth" className="no-underline white pb2" activeClassName="bb bw1">Futebol de Base</Link></li>
                  {/* <li className="mb4 ph3-l mb0-l w-100 w-auto-l"><Link to="/" className="no-underline white pb2">Contato</Link></li> */}
                </ul>
              </div>
              <div className="w-100 w-10-l" />
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
